// IMPORTS
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { AppState } from '../../redux/reducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

// CONTAINER COMPONENS
import DashboardContainer from '../DashboardContainer';

// PRESENTATION COMPONENT
import Cards from '../../components/Cards';
import { doDownloadPdf } from '../CardResultContainer/actions';
import { doGetUserData } from '../AccountContainer/actions';
import { doNavClose } from '../NavigationContainer/actions';
import { doModalShow, doModalHide } from '../ModalBackgroundContainer/actions';
import Modal from '../../components/Modal';
import ModalBackgroundContainer from '../ModalBackgroundContainer';
import CardModal, { CardType } from '../../components/CardModal';
import { shouldStudentRedirectToEndSurvey } from '../../util/account';

// TYPES
interface IProps {
  location: {
    state: { hasCompletedConsumerEndSurvey: boolean };
  };
}

type StateProps = AppState;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type FinalProps = IProps & StateProps & DispatchProps;

// MAIN COMPONENT
/**
 *
 * @param props
 */
const CardsContainer: React.FC<FinalProps> = props => {
  const hasCompletedConsumerEndSurvey: boolean =
    (props.location.state && props.location.state.hasCompletedConsumerEndSurvey) ||
    props.accountReducer.get('hasCompletedConsumerEndSurvey');
  const isConsumerGroup: boolean = props.accountReducer.get('isConsumerGroup');
  const sortResults = props.resultReducer.get('cards').toJS() || [];
  const role = props.accountReducer.get('role');
  const [showCardInfoModal, setShowCardInfoModal] = useState(false);
  const [cardType, setCardType] = useState<CardType>(null);
  const activeElement = useRef<HTMLElement | null>(null);
  const onClickDownload = (args: any) => {
    props.doDownloadPdf(args);
  };

  const { doNavClose } = props;

  const handleCardInfoClick = (cardType: CardType) => {
    activeElement.current = document.activeElement as HTMLElement;
    setCardType(cardType);
    setShowCardInfoModal(true);
    props.doModalShow();
  };

  const handleCardInfoModalClose = useCallback(() => {
    setShowCardInfoModal(false);
    setCardType(null);
    if (activeElement.current) {
      setTimeout(() => {
        activeElement.current?.focus();
        activeElement.current = null;
        props.doModalHide();
      }, 0);
    }
  }, [props]);

  useEffect(() => {
    if (document.body.offsetWidth <= 768) {
      doNavClose();
    }
  }, [doNavClose]);

  useEffect(
    () => {
      if (
        shouldStudentRedirectToEndSurvey(
          role,
          hasCompletedConsumerEndSurvey,
          sortResults,
          isConsumerGroup
        )
      ) {
        props.push('/dashboard/cards/results', { shouldUpdateResult: false });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasCompletedConsumerEndSurvey, sortResults, role, isConsumerGroup]
  );

  useEffect(() => {
    if (showCardInfoModal) {
      const escapeHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          handleCardInfoModalClose();
        }
      };

      document.addEventListener('keyup', escapeHandler);

      return () => {
        document.removeEventListener('keyup', escapeHandler);
      };
    }
  }, [showCardInfoModal, handleCardInfoModalClose]);

  return (
    <DashboardContainer>
      <Cards
        {...props}
        push={props.push}
        onClickDownload={onClickDownload}
        onCardInfoClick={handleCardInfoClick}
      />
      <ModalBackgroundContainer isDashboard={false} backgroundColor="rgba(255, 255, 255, 0.1)" />
      {showCardInfoModal && (
        <Modal
          maxHeight={600}
          minHeight={null}
          hideHeader={true}
          show={true}
          loading={false}
          onCancel={handleCardInfoModalClose}
          noPadding
        >
          <CardModal type={cardType} />
        </Modal>
      )}
    </DashboardContainer>
  );
};

// REDUX
/**
 *
 * @param state
 */
const mapStateToProps = (state: AppState) => ({ ...state });

/**
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      doDownloadPdf,
      doGetUserData,
      doNavClose,
      doModalShow,
      doModalHide
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CardsContainer);
