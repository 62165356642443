import React, { useState, useContext, useRef } from 'react';
import LanguageContext from '../../../../locale/LanguageContext';
import CardSelectionSectionStyle, {
  Card,
  CardHolder,
  CardContent,
  NameContainer,
  HeartWrapper,
  Reminder,
  Listbox,
  Option,
  OptionWrapper,
  InfoWrapper,
  InfoWrapperContainer
} from './styles';
import { FaRegHeart, FaInfo } from 'react-icons/fa';
import { CardType } from '../../../CardModal';
import { CardEnum } from '../../../Card';
import AddCustomCardButton from '../AddCustomCardButton';
import { CustomCard, CardSelectionType } from '../../../../types/customCard';

interface IProps {
  Cards?: CardSelectionType[];
  cardType: string | null;
  headerText: string;
  showReadMorePopUp: boolean;
  handleFocusListbox: (cardType : string | null) => void;
  handleOptionBlur: () => void;
  cardTypes: (
    type: CardEnum | string,
    isNbSchool?: boolean,
    role?: string,
    language?: string,
    cardObj?: any
  ) => CardType;
  onLike: (i: number, cardResult: string | null | CustomCard) => void;
  handleListboxNavigation: (e: React.KeyboardEvent, i: number) => void;
  liked: (c: CardSelectionType) => any;
  likedCards: any[];
  handleClickInfo: (e: React.MouseEvent<HTMLButtonElement>, callback: Function) => void;
  onKeyPress: (e: React.KeyboardEvent, callback: Function) => void;
  onClickInfo: (type: CardType) => (e: any) => void;
  showAddCustomCardButton?: boolean;
  onAddCustomCardButtonClick?: () => void;
}

type Props = IProps;
const CardSelectionSection: React.FC<Props> = props => {
  const { translate, language } = useContext(LanguageContext);
  const {
    Cards,
    cardType,
    headerText,
    showReadMorePopUp,
    handleFocusListbox,
    handleOptionBlur,
    cardTypes,
    onLike,
    handleListboxNavigation,
    liked,
    likedCards,
    handleClickInfo,
    onKeyPress,
    onClickInfo,
    showAddCustomCardButton = false,
    onAddCustomCardButtonClick = () => {}
  } = props;
  const [infoButtonFocused, setInfoButtonFocused] = useState(false);
  const listboxRef = useRef<HTMLElement | null>(null);

  /* eslint-enable */

  return (
    <CardSelectionSectionStyle>
      <h2>{headerText}</h2>
      <Listbox
        role="listbox"
        aria-orientation="horizontal"
        aria-labelledby="card-selection-heading"
        aria-multiselectable={true}
        ref={listboxRef}
        onFocus={() => handleFocusListbox(cardType)}
        isMarginRequired={showReadMorePopUp}
      >
        {Cards?.map((card, i) => {
          const hasId = (card && !!card.id) || false;
          const type = cardTypes(card.name || '', false, '', language, card);
          const optionValue = hasId ? card.id : type?.name;
          return (
            <CardHolder key={i}>
              <OptionWrapper>
                <Option
                  id={optionValue}
                  value={optionValue}
                  role="option"
                  type="checkbox"
                  name="pick-three"
                  aria-selected={!!liked(card)}
                  aria-label={type?.name}
                  onClick={onLike(i, card.result || card)}
                  onKeyDown={(e) => handleListboxNavigation(e, i)}
                  onBlur={handleOptionBlur}
                  aria-disabled={likedCards.length >= 3 && likedCards
                            .map((likedCard: any) => likedCard.name)
                            .indexOf(card.name) === -1}
                />
                <div>
                  <Card
                    visible={true}
                    key={i}
                    type={card.name}
                    cardObj={card}
                    watermarkImageUrl={(card && card.watermarkImageUrl) || ''}
                    logoImageUrl={(card && card.logoImageUrl) || ''}
                  >
                    {showReadMorePopUp && i === 0 && (
                      <Reminder>
                        <span></span>
                        <span></span>
                        <span></span>
                        <p id="read-more" aria-hidden={true}>{translate('PickThree.ReadMore')}</p>
                      </Reminder>
                    )}
                    <CardContent>
                      <div>
                        <InfoWrapperContainer>
                          <InfoWrapper
                            id={`career-information-${i}`}
                            role="button"
                            aria-label={infoButtonFocused ? translate('PickThree.Info') : null}
                            onClick={(e) => handleClickInfo(e, onClickInfo(type))}
                            onKeyPress={(e) => onKeyPress(e, onClickInfo(type))}
                            onFocus={() => setInfoButtonFocused(true)}
                            onBlur={() => setInfoButtonFocused(false)}
                            tabIndex={0}
                          >
                            <FaInfo />
                          </InfoWrapper>
                        </InfoWrapperContainer>
                        <HeartWrapper active={!!liked(card)}>
                          <FaRegHeart />
                          {liked(card)}
                        </HeartWrapper>
                      </div>
                      <NameContainer>{type?.name}</NameContainer>
                    </CardContent>
                  </Card>
                </div>
              </OptionWrapper>
            </CardHolder>
          );
        })}
        {showAddCustomCardButton && <AddCustomCardButton onClick={onAddCustomCardButtonClick} />}
      </Listbox>
    </CardSelectionSectionStyle>
  );
};
export default CardSelectionSection;
