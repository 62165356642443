import React, { useContext } from 'react';
import { LiveMessage } from 'react-aria-live';
import CardStyles, {
  CardWrapper,
  PlaceholderContainer,
  CardsContainer,
  ButtonsContainer,
  MainContainer
} from './styles';
import Button from '../../styles/Button';
import { Push } from 'connected-react-router';
import { AppState } from '../../redux/reducer';
import { cardTypes } from '../Card';
import { Card, InfoWrapperContainer, InfoWrapper } from '../CardSort/styles';
import Loader from '../../styles/Loader';
import Success from '../../styles/Success';
import Error from '../../styles/Error';
import NativeLink from '../../styles/NativeLink';
import { FaInfo } from 'react-icons/fa';
import LanguageContext from '../../locale/LanguageContext';
import gaUtil from '../../util/ga';
import * as env from '../../config/env.json';
import { useAriaLiveMessage } from '../../hooks';
import { getExtraCardContent, isCustomCard, isExtraCard } from '../../util/card';
import { CardType } from '../CardModal';
import RocketImage from '../../styles/assets/rocket.svg';
import Link from '../../styles/Link';
interface IProps {
  onClickDownload: (args: { language: String; email: Boolean }) => void;
  onCardInfoClick: (card: CardType) => void;
}

type StateProps = AppState;

interface DispatchProps {
  push: Push;
}

type Props = IProps & StateProps & DispatchProps;

const Cards: React.FC<Props> = props => {
  const { language, translate } = useContext(LanguageContext);
  const cardsResults = props.resultReducer.get('cards');
  const role = props.accountReducer.get('role');
  const isConsumerGroup = props.accountReducer.get('isConsumerGroup');
  const isFreemiumGroup = props.accountReducer.get('isFreemiumGroup');
  const hasResults = cardsResults && cardsResults.toJS().length > 0;
  const error = props.resultReducer.get('error');
  const success = props.resultReducer.get('email') && !error;
  const successMessage = success ? translate('Cards.EmailSent') : '';
  const errorMessage = error ? translate('error.defaultMessage') : '';
  const extraCards = props.resultReducer.get('extraCards');
  const ariaLiveMessage = useAriaLiveMessage(successMessage, errorMessage);

  const onClick = () => {
    props.push('/dashboard/cards/intro');
  };

  const renderComponent = ({
    hasResults,
    isConsumerGroup,
    isFreemiumGroup
  }: {
    hasResults: boolean;
    isConsumerGroup: boolean;
    isFreemiumGroup: boolean;
  }) => {
    if (!hasResults) {
      return (
        <PlaceholderContainer>
          <h1>{translate('Cards.PlaceholderHeading')}</h1>
          <div>
            <Button styleType="primary-white" onClick={onClick}>
              {translate('Cards.Buttons.SortCards')}
            </Button>
          </div>
        </PlaceholderContainer>
      );
    }
    if (isFreemiumGroup === true && isConsumerGroup === true) {
      return (
        <MainContainer>
          <h1>{translate('CardSortConclusion.HeaderFreemiumConsumer')}</h1>
          <img src={RocketImage} alt="rocket" className="rocket-icon" />
          <h2>{translate('CardSortConclusion.FeedbackHeader')}</h2>
          <Link doInherit={true} to={'/dashboard/support'}>
            {translate('CardResult.Bottom.Feedback')}
          </Link>
        </MainContainer>
      );
    }
    if (isConsumerGroup === true && isFreemiumGroup === false) {
      return (
        <React.Fragment>
          <h1>{translate('Cards.Consumer.Result.Heading')}</h1>
          <h3>{translate('Cards.Consumer.Result.SubHeading')}</h3>
          <div className="card-result__video-container">
            <iframe
              src={env.CONSUMER_AFTER_SORT_VIDEO_URL}
              frameBorder="0"
              allowFullScreen
              title="Sort Success Video"
            ></iframe>
          </div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <h1>{translate('Cards.Heading')}</h1>
        <CardsContainer>
          {props.resultReducer
            .get('cards')
            .toJS()
            .map((card, i) => {
              const isCustom = isCustomCard(card);
              const isExcludedExtraCard = isExtraCard(card);
              const cardObj =
                isCustom || isExcludedExtraCard ? card : getExtraCardContent(card, extraCards);
              const cardName =
                isExcludedExtraCard || !isCustom
                  ? typeof card === 'string'
                    ? card
                    : card.name
                  : '';
              const type = cardTypes(cardName, false, '', language, cardObj);
              return (
                <CardWrapper key={i}>
                  <Card
                    visible={true}
                    type={cardName}
                    cardObj={cardObj}
                    logoImageUrl={(cardObj && cardObj.logoImageUrl) || ''}
                    watermarkImageUrl={(cardObj && cardObj.watermarkImageUrl) || ''}
                  />
                  <span>{type.name}</span>
                  <InfoWrapperContainer>
                    <InfoWrapper
                      onClick={() => props.onCardInfoClick(type)}
                      aria-label={translate('CardSort.Info')}
                    >
                      <FaInfo />
                    </InfoWrapper>
                  </InfoWrapperContainer>
                </CardWrapper>
              );
            })}
        </CardsContainer>
        <ButtonsContainer>
          {props.resultReducer.get('loading') ? (
            <Loader white />
          ) : (
            <>
              {success && <Success>{successMessage}</Success>}
              {error && <Error>{errorMessage}</Error>}
              <Button styleType="primary-white" outline onClick={onClick}>
                {translate('Cards.Buttons.SortCardsAgain')}
              </Button>
              <React.Fragment>
                <Button
                  styleType="primary-white"
                  onClick={() => {
                    gaUtil.events.results.downloadResults(role);
                    props.onClickDownload({
                      language,
                      email: false
                    });
                  }}
                >
                  {translate('Cards.Buttons.DownloadPDF')}
                </Button>
                <Button
                  style={{ color: '#fff', marginBottom: '0.5rem' }}
                  onClick={e => {
                    e.preventDefault();
                    gaUtil.events.results.emailResults(role);
                    props.onClickDownload({
                      language,
                      email: true
                    });
                  }}
                >
                  {translate('Cards.Buttons.EmailPDF')}
                </Button>
                <NativeLink
                  styleType="button"
                  href={
                    language === 'fr'
                      ? '/French_Challenge_Profile_Accessible.pdf'
                      : '/English_Challenge_Profile_Accessible.pdf'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translate('CardResult.AccessiblePdf')}
                </NativeLink>
              </React.Fragment>
            </>
          )}
        </ButtonsContainer>
      </React.Fragment>
    );
  };

  return (
    <CardStyles>
      <LiveMessage message={ariaLiveMessage} aria-live="polite" />
      {props.resultReducer.get('resultsLoading') ? (
        <Loader white />
      ) : (
        renderComponent({ isConsumerGroup, isFreemiumGroup, hasResults })
      )}
    </CardStyles>
  );
};

export default Cards;
