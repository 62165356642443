import React, { useContext, useState, useRef, Fragment } from 'react';
import { t as translate } from 'i18n-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';

import LanguageContext from '../../locale/LanguageContext';
import jobLinks from '../../locale/jobLinks';
import gaUtil from '../../util/ga'

import cards, { bulletNumberKeys } from './cards';
import CardStyles, {
  CardImage,
  CardText,
  CardLogoImage,
  BulletDescriptionContainer
} from './styles';
import Colors from '../../styles/Colors';
import Button from '../../styles/Button';
// import { CardAttributesResult } from '../../util/card';
// import { CustomCard } from '../../types/customCard';

export type CardEnum =
  | 'CardBigMachines'
  | 'CardBuildBig'
  | 'CardCareCars'
  | 'CardControlWater'
  | 'CardFixBrokenStuff'
  | 'CardGivePower'
  | 'CardGreatProducts'
  | 'CardGrowFood'
  | 'CardHelpOthersSolve'
  | 'CardHelpPeople'
  | 'CardHelpTeamsAchieve'
  | 'CardMakeBuildingsWork'
  | 'CardMakeItBeautiful'
  | 'CardMakeMatters'
  | 'CardMasterTool'
  | 'CardPeopleBetter'
  | 'CardPrepareFood'
  | 'CardSetThingsUp'
  | 'CardSpecialEquipment'
  | 'CardSpecialMaterials'
  | 'CardUseComputers'
  | 'CardWeatherElements'
  | 'CardWorkOnHomes'
  | 'CardYourOwnBusiness'
  | 'CardHealthcare'
  | 'CardHealthyLiving'
  | 'CardHealthInformatics'
  | 'CardMentalHealth'
  | 'CardWealth'
  | 'CardSustainableDevelopment'
  | 'CardFairTrade'
  | 'CardEntrepreneurship'
  | 'CardFutureLeaders'
  | 'CardAI'
  | 'CardBioTech'
  | 'CardBigData'
  | 'CardVR'
  | 'CardBrain'
  | 'CardSchoolFuture'
  | 'CardProtectSociety'
  | 'CardReachPotential'
  | 'CardPoverty'
  | 'CardRespect'
  | 'CardSustainableEnergy'
  | 'CardBiodiversity'
  | 'CardTransportation'
  | 'CardWaste'
  | 'CardFoodSystems'
  | 'CardIdeasEmotions'
  | 'CardMasterTools'
  | 'CardInspireArt'
  | 'CardMedicine'
  | 'CardMeaningfulExperiences'
  | 'CardSportsPerformance'
  | 'CardBlockChain'
  | 'CardLearnFromAnimal';

interface IProps {
  type?: CardEnum | string;
  style?: any;
  className?: string;
  visible?: boolean;
  isNbSchool?: boolean;
  cardObj?: any; // CardAttributesResult | CustomCard | null;
  isEdit?: boolean;
  logoImageUrl?: string;
  watermarkImageUrl?: string;
}

type CardOptions = {
  key: string;
  bullets: Array<string>;
};

const CardContent = ({
  cardOptions,
  isNbSchool,
  name,
  role,
  hideCareers = false
}: {
  cardOptions: any;
  isNbSchool?: boolean;
  name: string;
  role: string;
  hideCareers?: boolean;
}) => {
  const { key, bullets } = cardOptions;
  const {
    content: extraCardContent,
    jobLinks: extraCardJobLinks,
    bulletHeading,
    bulletDescription: extraCardBulletDescription
  } = cardOptions; // extra card
  const { /* title: cardTitle, */ description: cardDescription } = cardOptions; // custom card

  const [careersOpen, setCareersOpen] = useState(false);
  const { translate, language } = useContext(LanguageContext);
  const careersButton = useRef<HTMLButtonElement>(null);

  const toggleCareers = () => {
    if (!careersOpen) {
      gaUtil.events.cardSort.viewSampleCareers(role, {card: name})
    }

    setCareersOpen(!careersOpen);
  };

  const anyJobLinks = (jobLinks as any)
  const jobLinkTable = anyJobLinks && anyJobLinks[language] && anyJobLinks[language].nb

  function renderBulletListWithLinks(key: string, bullets: any, extraCardContent: any = null) {
    if (extraCardContent) {
      const bulletContents = bulletNumberKeys.reduce((allBulletContent: any[], numberKey: string) => {
          const bulletKeyName = `bullet${numberKey}`;
          const bulletTitle = extraCardContent[bulletKeyName] || '';
          const bulletDescription =
            (extraCardBulletDescription && extraCardBulletDescription[bulletKeyName]) || '';
          if (bulletTitle || bulletDescription) {
            const jobLink = (extraCardJobLinks && extraCardJobLinks[bulletKeyName]) || '';
            return [
              ...allBulletContent,
              {
                key: bulletKeyName,
                bulletTitle,
                bulletDescription,
                jobLink
              }
            ];
          }
          return allBulletContent;
        },
        []
      );
      return bulletContents.map(({ key, bulletTitle, bulletDescription, jobLink }) => {
        return (
          <li key={key}>
            {jobLink ? (
              <Fragment>
                <a
                  href={jobLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    gaUtil.events.cardSort.clickOnCareer(role, { career: bulletTitle });
                  }}
                >
                  {bulletTitle}
                </a>
                {bulletDescription ? (
                  <BulletDescriptionContainer>{bulletDescription || ''}</BulletDescriptionContainer>
                ) : null}
              </Fragment>
            ) : (
              <Fragment>
                {`${bulletTitle || ''} `}
                {bulletDescription ? (
                  <BulletDescriptionContainer>{bulletDescription}</BulletDescriptionContainer>
                ) : null}
              </Fragment>
            )}
          </li>
        );
      });
    } else {
      return (
        <>
          {bullets.map((bullet: string) => {
            const translationKey = `${key}.${bullet}`
            const jobLink = jobLinkTable[translationKey]
    
            if (isNbSchool && jobLink) {
              return (
                <li key={`${key}-${bullet}`}>
                  <a href={jobLink} target="_blank" rel="noopener noreferrer" onClick={() => {
                    gaUtil.events.cardSort.clickOnCareer(role, {career: translate(translationKey)})
                  }}>
                    {translate(translationKey)}
                  </a>
                </li>
              )
            }
    
            return (
              <li key={`${key}-${bullet}`}>{translate(translationKey)}</li>
            )
          })}
        </>
      )
    }
  }

  if (cardOptions.key === 'cardWealth') {
    return (
      <>
        <p>{translate('cardWealth.Paragraph.PartOne')}</p>
        <span>
          <h3 id="sample-careers">{translate('cardGenericHeading.Title')}</h3>
          <Button
            styleType="secondary"
            ref={careersButton}
            id="careers-expand-button"
            onClick={toggleCareers}
            aria-label={careersOpen ? translate('AriaLabel.Collapse') : translate('AriaLabel.Expand')}
            aria-expanded={careersOpen}
            aria-labelledby="sample-careers"
          >
            <FontAwesomeIcon
              icon={careersOpen ? faMinusCircle : faPlusCircle}
            />
          </Button>
        </span>
        {careersOpen && (
          <>
            <ul>
              {renderBulletListWithLinks('cardWealth', ['bulletOne', 'bulletTwo', 'bulletThree', 'bulletFour', 'bulletFive'])}
            </ul>

            <p>
              <strong>{translate('cardWealth.Paragraph.PartTwo')}</strong>
            </p>
            <ul>
              {renderBulletListWithLinks('cardWealth', ['bulletSix', 'bulletSeven', 'bulletEight'])}
            </ul>
          </>
        )}

        <p>
          <strong>{translate('cardWealth.Paragraph.PartThree')}</strong>
        </p>

        <ul>
          <li>{translate('cardWealth.bulletNine')}</li>
          <li>{translate('cardWealth.bulletTen')}</li>
          <li>{translate('cardWealth.bulletEleven')}</li>
          <li>{translate('cardWealth.bulletTwelve')}</li>
          <li>{translate('cardWealth.bulletThirteen')}</li>
        </ul>
      </>
    );
  }

  if (cardOptions.key === 'cardEntrepreneurship') {
    return (
      <>
        <p>{translate('cardEntrepreneurship.Paragraph.PartOne')}</p>
        <p>{translate('cardEntrepreneurship.Paragraph.PartTwo')}</p>
        <p>{translate('cardEntrepreneurship.Paragraph.PartThree')}</p>
      </>
    );
  }

  return (
    <>
      <CardText>
        {key ? translate(`${key}.Paragraph.PartOne`) : ''}
        {!key && extraCardContent ? extraCardContent['Paragraph.PartOne'] || '' : ''}
        {!key && !extraCardContent && cardDescription ? cardDescription : ''}
      </CardText>
      {!hideCareers && (
        <span>
          <h3 id="sample-careers">{bulletHeading || translate('cardGenericHeading.Title')}</h3>
          <Button
            styleType="secondary"
            ref={careersButton}
            id="careers-expand-button"
            onClick={toggleCareers}
            aria-label={careersOpen ? translate('AriaLabel.Collapse') : translate('AriaLabel.Expand')}
            aria-expanded={careersOpen}
            aria-labelledby="sample-careers"
          >
            <FontAwesomeIcon
              icon={careersOpen ? faMinusCircle : faPlusCircle}
            />
          </Button>
        </span>
      )}
      {careersOpen && (extraCardContent || (bullets && bullets.length)) && (
        <ul>{renderBulletListWithLinks(key, bullets, extraCardContent)}</ul>
      )}
      {key || extraCardContent ? (
        <CardText>
          {key ? translate(`${key}.Paragraph.PartTwo`) : extraCardContent['Paragraph.PartTwo'] || ''}
        </CardText>
      ) : null}
    </>
  );
};

export const cardTypes = (
  type: CardEnum | string,
  isNbSchool = false,
  role: string = '',
  language: string = 'en',
  cardObj: any = null // CardAttributesResult | CustomCard
) => {
  const colorsAsAny = Colors as any;
  const keyLowercase = type && type.charAt(0).toLowerCase() + type.slice(1);
  if (!type) {
    // custom card
    const customCardName = `${Date.now()}${cardObj.title}`;
    return {
      src: cardObj.imageUrl,
      color: cardObj.color || Colors.purple77,
      name: cardObj.title,
      content: (
        <CardContent
          name={customCardName}
          isNbSchool={isNbSchool}
          role={role}
          hideCareers
          cardOptions={{
            title: cardObj.title,
            imageUrl: cardObj.imageUrl,
            description: cardObj.description
          }}
        />
      )
    };
  } else if (cardObj?._id && cardObj?.name && language) {
    // extra cards
    const localizedCardContent = cardObj[language];
    const bulletContentCount = bulletNumberKeys.filter(numberKey => {
      const bulletKeyName = `bullet${numberKey}`;
      const bulletTitle =
        (localizedCardContent &&
          localizedCardContent.content &&
          localizedCardContent.content[bulletKeyName]) ||
        '';
      const bulletDescription =
        (localizedCardContent &&
          localizedCardContent.bulletDescription &&
          localizedCardContent.bulletDescription[bulletKeyName]) ||
        '';
      return !!(bulletTitle || bulletDescription);
    });
    return {
      src: localizedCardContent.imageUrl,
      color: cardObj.color || colorsAsAny.blue00,
      name: localizedCardContent.title,
      content: (
        <CardContent
          cardOptions={localizedCardContent}
          isNbSchool={isNbSchool}
          hideCareers={bulletContentCount.length < 1}
          name={localizedCardContent.title}
          role={role}
        />
      )
    };
  } else {
    const baseCardAssets = cards as any;
    const cardName = translate(`${baseCardAssets[keyLowercase]?.key}.Title`);
    return {
      src: baseCardAssets[keyLowercase].src,
      color: colorsAsAny[keyLowercase],
      name: cardName,
      content: (
        <CardContent
          cardOptions={baseCardAssets[keyLowercase]}
          isNbSchool={isNbSchool}
          name={cardName}
          role={role}
        />
      ),
      notes: 'Lorem ipsum dolor sit amet consectetur adpisicing elit.'
    };
  }
};

// MAIN COMPONENT
/**
 * Main component
 * @param {*} props state and dispatch
 * @returns {Component} Presentation compoennt
 */
const Card: React.FC<IProps> = props => {
  const { language } = useContext(LanguageContext);
  const { isEdit = false, logoImageUrl = '', watermarkImageUrl = '' } = props;
  const cardTypesObj = isEdit
    ? null
    : cardTypes(props.type || '', false, '', language, props.cardObj);
  return (
    <CardStyles
      className={props.className}
      color={cardTypesObj ? cardTypesObj.color : Colors.purple77} // TODO: default card color
      watermarkImageUrl={watermarkImageUrl}
      style={props.style || {}}
    >
      {!isEdit && cardTypesObj && cardTypesObj.src ? (
        <CardImage alt="" src={cardTypesObj.src} />
      ) : null}
      {props.children}
      {logoImageUrl ? <CardLogoImage alt="" src={logoImageUrl} /> : null}
    </CardStyles>
  );
};

export default Card;
