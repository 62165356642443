import React, { useState, useContext, useRef } from 'react';
import { LiveMessage } from 'react-aria-live';
import LanguageContext from '../../locale/LanguageContext';
import CardSelectionSection from './components/CardSelectionSection';
import PickThreeStyles, {
  TransitionStyles,
  ConfirmWrapper,
} from './styles';
import { AppState } from '../../redux/reducer';
import { Push } from 'connected-react-router';
import { cardTypes } from '../Card';
import ButtonStyles from '../../styles/Button';
import { CardType } from '../CardModal';
import { useAriaLiveMessage } from '../../hooks';
import { useCustomCards, MAX_CUSTOM_CARDS_ALLOWED } from './hooks/useCustomCards';
import EditCustomCard from '../EditCustomCard';
import { CustomCard, CardSelectionRankingType, CardSelectionType } from '../../types/customCard';

interface IProps {
  onClickInfo: (type: CardType) => (e: any) => void;
  onConfirm: (cards: any /* CardEnum[] | string[] | CardSelectionType[] */) => void;
}

type StateProps = AppState;

interface DispatchProps {
  push: Push;
  doModalShow: Function;
  doModalHide: Function;
}

type Props = IProps & StateProps & DispatchProps;
const PickThree: React.FC<Props> = props => {
  const { translate, language } = useContext(LanguageContext);
  const { onClickInfo, onConfirm } = props;
  const [isAddCustomCardPageOpen, setIsAddCustomCardPageOpen] = useState(false);
  const { tempCustomCards, setTempCustomCards } = useCustomCards();
  const { state = {} as any } = props.router.location;
  const cards = (state.cards || []) as CardSelectionType[];
  const [likedCards, setLikedCards] = useState([] as CardSelectionRankingType[]);
  const [isTransition, setIsTransition] = useState(true);
  const filteredCards = cards.filter((card: any) => card.result && card.result !== 'dislike');
  const interestedCards = cards.filter((card: any) => card.result && card.result === 'like');
  const veryInterestedCards = cards
    .filter((card: any) => card.result && card.result === 'superlike')
    .concat(tempCustomCards as CardSelectionType[]);
  const getCardName = (card: CardSelectionType) => {
    if (card.id) {
      return card.id;
    }
    return (
      cardTypes(card.name, false, '', language, card) &&
      cardTypes(card.name, false, '', language, card).name
    );
  };
  const cardNames = filteredCards.map(card => getCardName(card));
  const interestedCardNames = interestedCards.map(card => getCardName(card));
  const veryInterestedCardNames = veryInterestedCards.map(card => getCardName(card));
  const focusRef = useRef<HTMLElement | null>(null);
  const [message, setMessage] = useState('');
  const ariaLiveMessage = useAriaLiveMessage(message);
  const showPopUpVeryInterestedCards = veryInterestedCards.length !== 0;

  function isSuperLike(cardResult: string | null | CustomCard) {
    return cardResult === 'superlike' || typeof cardResult === 'object';
  }

  if (!state || !state.cards || !state.cards.length) {
    props.push('/dashboard/cards');
    return null;
  }

  const onLike = (i: number, cardResult: string | null | CustomCard) => (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (e) {
      focusRef.current = document.getElementById(
        isSuperLike(cardResult) ? veryInterestedCardNames[i] : interestedCardNames[i]
      );
      focusRef.current && focusRef.current.focus();
    }
    const liked = [...likedCards];
    const next = [1, 2, 3].find(i => liked.findIndex(x => x.rank === i) === -1);
    const index = liked.findIndex(x => {
      return (
        (x.name || x.id) ===
        (isSuperLike(cardResult)
          ? veryInterestedCards[i].name || veryInterestedCards[i].id
          : interestedCards[i].name)
      );
    });
    setMessage(' ');
    if (index === -1) {
      if (liked.length === 3) {
        setTimeout(() => {
          setMessage('Maximum cards selected, unselect a card first');
        });
        return;
      }
      liked.push({
        ...(isSuperLike(cardResult) ? veryInterestedCards[i] : interestedCards[i]),
        rank: next
      });
    } else {
      liked.splice(index, 1);
    }
    setLikedCards(liked);
  };

  const liked = (c: CardSelectionRankingType) => {
    const choice = likedCards.find((x: any) => {
      return (x.name || x.id) === (c.name || c.id);
    });
    return choice ? choice.rank : false;
  };

  const handleKeyPress = (e: React.KeyboardEvent, callback: Function) => {
    e.stopPropagation()
    e.preventDefault()
    if (e.key === ' ' || e.key === 'Enter') {
      callback(e)
    }
  }

  const handleClickInfo = (e: React.MouseEvent<HTMLButtonElement>, callback: Function) => {
    e.stopPropagation();
    callback(e);
  }

  const handleListboxNavigation = (e: React.KeyboardEvent, i: number) => {
    e.stopPropagation();
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
    if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
      focusRef.current = document.getElementById(cardNames[i + 1]);
      focusRef.current && focusRef.current.focus();
    }
    if ((e.key === 'ArrowLeft' && i > 0) || e.key === 'ArrowUp') {
      focusRef.current = document.getElementById(cardNames[i - 1]);
      focusRef.current && focusRef.current.focus();
    }
  };

  const handleFocusListbox = (cardType: string | null) => {
    if (!focusRef.current) {
      const likedCardNames = likedCards.map((card: any) => getCardName(card));
      const selection = cardNames.find(card => likedCardNames.indexOf(card) !== -1);
      const card = likedCards.length
        ? selection
        : cardType === 'superlike' ? veryInterestedCardNames[0] : interestedCardNames[0];
      focusRef.current = document.getElementById(card || (cardType === 'superlike' ? veryInterestedCardNames[0] : interestedCardNames[0]));
      focusRef.current && focusRef.current.focus();
    }
  };

  const handleOptionBlur = () => {
    setTimeout(() => {
      const current = document.activeElement;
      if (!(current && current.id) || (current.id.indexOf('career-information') === -1 && cardNames.indexOf(current.id) === -1)) {
        focusRef.current = null;
      }
    });
  }

  /* eslint-disable */
  if (isTransition) {
    document.getElementById('header-root')?.setAttribute('inert', '')
    return (
      <TransitionStyles>
        <h1>
          {translate('PickThree.PhaseOne.Heading')}{' '}
          <span>
            ⭐️
          </span>
        </h1>
        <p>{translate('PickThree.PhaseOne.Paragraph')}</p>
        <ButtonStyles outline onClick={() => setIsTransition(false)}>
          {translate('PickThree.PhaseOne.Button')}
        </ButtonStyles>
      </TransitionStyles>
    );
  }

  if (isAddCustomCardPageOpen) {
    return (
      <EditCustomCard
        onCancel={() => setIsAddCustomCardPageOpen(false)}
        onUpdateCustomCard={(value: CustomCard) => {
          setTempCustomCards([
            ...tempCustomCards,
            value
          ]);
          setIsAddCustomCardPageOpen(false);
        }}
        accountId={props.accountReducer.get('_id')}
        customCardLength={tempCustomCards.length}
        doModalShow={props.doModalShow}
        doModalHide={props.doModalHide}
      />
    );
  }
  /* eslint-enable */

  document.getElementById('header-root')?.removeAttribute('inert')
  return (
    <PickThreeStyles>
      <LiveMessage message={ariaLiveMessage} aria-live="polite" clearOnUnmount />
      <h1 id="pick-three-heading">{translate('PickThree.PhaseTwo.Heading')}</h1>
      <CardSelectionSection
        Cards={veryInterestedCards}
        cardType={veryInterestedCards[0] && veryInterestedCards[0].result}
        headerText={translate('CardSort.SuperInterested')}
        showReadMorePopUp={showPopUpVeryInterestedCards}
        handleFocusListbox={handleFocusListbox}
        handleOptionBlur={handleOptionBlur}
        cardTypes={cardTypes}
        onLike={onLike}
        handleListboxNavigation={handleListboxNavigation}
        liked={liked}
        likedCards={likedCards}
        handleClickInfo={handleClickInfo}
        onKeyPress={handleKeyPress}
        onClickInfo={onClickInfo}
        showAddCustomCardButton={tempCustomCards.length < MAX_CUSTOM_CARDS_ALLOWED}
        onAddCustomCardButtonClick={() => {
          setIsAddCustomCardPageOpen(true);
        }}
      />
      { interestedCards.length !== 0 &&
        <CardSelectionSection
          Cards={interestedCards}
          cardType={interestedCards[0].result}
          headerText={translate('CardSort.Interested')}
          showReadMorePopUp={!showPopUpVeryInterestedCards}
          handleFocusListbox={handleFocusListbox}
          handleOptionBlur={handleOptionBlur}
          cardTypes={cardTypes}
          onLike={onLike}
          handleListboxNavigation={handleListboxNavigation}
          liked={liked}
          likedCards={likedCards}
          handleClickInfo={handleClickInfo}
          onKeyPress={handleKeyPress}
          onClickInfo={onClickInfo}
        />
      }
      <ConfirmWrapper>
        <ButtonStyles
          styleType="primary-white"
          onClick={() => {
            onConfirm(
              likedCards
                .sort(
                  (a: CardSelectionRankingType, b: CardSelectionRankingType) =>
                    (a.rank || 0) - (b.rank || 0)
                )
                .map((x: CardSelectionRankingType) => {
                  return x.id ? x : x.name;
                })
            );
          }}
          disabled={likedCards.length < 3}
        >
          {translate('PickThree.PhaseTwo.Button')}
        </ButtonStyles>
      </ConfirmWrapper>
    </PickThreeStyles>
  );
};
export default PickThree;
