import { fromJS } from 'immutable';
import {
  ACCOUNT_GET_USER_REQUEST,
  ACCOUNT_GET_USER_SUCCESS,
  ACCOUNT_GET_USER_FAIL,
  ACCOUNT_UPDATE_RESET,
  ACCOUNT_DETAILS_UPDATE_REQUEST,
  ACCOUNT_SCHOOL_CODE_UPDATE_REQUEST,
  ACCOUNT_SCHOOL_CODE_UPDATE_SUCCESS,
  ACCOUNT_SCHOOL_CODE_UPDATE_FAIL,
  ACCOUNT_DETAILS_UPDATE_FAIL,
  ACCOUNT_DETAILS_UPDATE_SUCCESS,
  ACCOUNT_PASSWORD_UPDATE_REQUEST,
  ACCOUNT_PASSWORD_UPDATE_FAIL,
  ACCOUNT_PASSWORD_UPDATE_SUCCESS,
  ACCOUNT_ADDRESS_UPDATE_REQUEST,
  ACCOUNT_ADDRESS_UPDATE_FAIL,
  ACCOUNT_ADDRESS_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_RESULTS
} from './types';
import { USER_LOGGED_OUT } from '../LoginContainer/types';

const BASE_STATE: {
  _id: string;
  loading: boolean;
  loadingDetails: boolean;
  loadingAddress: boolean;
  loadingPassword: boolean;
  loadingSchoolCode: boolean;
  error: null | { [field: string]: string } | string;
  errorDetails: null | { [field: string]: string };
  errorAddress: null | { [field: string]: string };
  errorPassword: null | { [field: string]: string };
  errorSchoolCode: null | { [field: string]: string };
  successDetails: boolean;
  successAddress: boolean;
  successPassword: boolean;
  successSchoolCode: boolean;
  isNbSchool: boolean;
  firstname: string;
  lastname: string;
  role: string;
  email: string;
  address: string;
  schoolname: string;
  state: string;
  phone: string;
  postalzip: string;
  country: string;
  city: string;
  code: string;
  isConsumerGroup: boolean;
  isFreemiumGroup: boolean;
  emailDomainWhitelist: string[];
  emailDomainBlacklist: string[];
  isEmailDomainWhitelistEnabled: Boolean;
  isEmailDomainBlacklistEnabled: Boolean;
  expiry: string;
  results: boolean;
  hasCompletedConsumerEndSurvey: boolean;
  excludedCards: string[];
} = {
  _id: '',
  loading: false,
  loadingDetails: false,
  loadingAddress: false,
  loadingPassword: false,
  loadingSchoolCode: false,
  error: null,
  errorDetails: null,
  errorAddress: null,
  errorPassword: null,
  errorSchoolCode: null,
  successDetails: false,
  successAddress: false,
  successPassword: false,
  successSchoolCode: false,
  isNbSchool: false,
  firstname: '',
  lastname: '',
  role: '',
  email: '',
  address: '',
  schoolname: '',
  state: '',
  phone: '',
  postalzip: '',
  country: '',
  city: '',
  code: '',
  isConsumerGroup: false,
  isFreemiumGroup: false,
  emailDomainWhitelist: [],
  emailDomainBlacklist: [],
  isEmailDomainWhitelistEnabled: false,
  isEmailDomainBlacklistEnabled: false,
  expiry: '',
  results: false,
  hasCompletedConsumerEndSurvey: false,
  excludedCards: []
};

type STATE_TYPE = typeof BASE_STATE;

export type ACCOUNT_REDUCER = ImmutableMap<STATE_TYPE>;

const INITIAL_STATE = fromJS(BASE_STATE) as ACCOUNT_REDUCER;

type CLAIMS = {
  claims?: {
    code: string;
    email: string;
    expiry: number;
    firstname: string;
    lastname: string;
    results: boolean;
    role: string;
  };
};

// const parseJWT = <T extends {} = any>(token: string) => {
//   const res = JSON.parse(window.atob(token.split('.')[1])) as T;
//   return res;
// };

export const accountReducer = (state = INITIAL_STATE, { type, payload }: ReduxAction) => {
  switch (type) {
    case USER_LOGGED_OUT:
      return INITIAL_STATE;
    case ACCOUNT_UPDATE_RESULTS:
      return state.set('results', payload.results);
    case ACCOUNT_GET_USER_REQUEST:
      return state
        .set('loading', true)
        .set('loadingDetails', false)
        .set('loadingAddress', false)
        .set('loadingPassword', false)
        .set('loadingSchoolCode', false)
        .set('error', null);
    case ACCOUNT_GET_USER_SUCCESS:
      return state
        .set('_id', payload._id)
        .set('loading', false)
        .set('firstname', payload.firstname)
        .set('lastname', payload.lastname)
        .set('role', payload.role)
        .set('results', payload.results || false)
        .set('email', payload.email)
        .set('address', payload.address || '')
        .set('schoolname', payload.schoolname || '')
        .set('state', payload.state || '')
        .set('phone', payload.phone || '')
        .set('postalzip', payload.postalzip || '')
        .set('country', payload.country || '')
        .set('city', payload.city || '')
        .set('code', payload.code || '')
        .set('isConsumerGroup', payload.isConsumerGroup || false)
        .set('isFreemiumGroup', payload.isFreemiumGroup || false)
        .set('emailDomainWhitelist', payload.emailDomainWhitelist || [])
        .set('emailDomainBlacklist', payload.emailDomainBlacklist || [])
        .set('isEmailDomainWhitelistEnabled', payload.isEmailDomainWhitelistEnabled || false)
        .set('isEmailDomainBlacklistEnabled', payload.isEmailDomainBlacklistEnabled || false)
        .set('expiry', payload.expiry || '')
        .set('isNbSchool', payload.isNbSchool || false)
        .set('hasCompletedConsumerEndSurvey', payload.hasCompletedConsumerEndSurvey || false)
        .set('excludedCards', fromJS(payload.excludedCards || []));
    case ACCOUNT_GET_USER_FAIL:
      return state
        .set('loading', false)
        .set('firstname', payload.firstname)
        .set('lastname', payload.lastname)
        .set('role', payload.role)
        .set('results', payload.results || false)
        .set('email', payload.email)
        .set('address', payload.address || '')
        .set('schoolname', payload.schoolname || '')
        .set('state', payload.state || '')
        .set('phone', payload.phone || '')
        .set('postalzip', payload.postalzip || '')
        .set('country', payload.country || '')
        .set('city', payload.city || '')
        .set('code', payload.code || '')
        .set('isConsumerGroup', payload.isConsumerGroup || false)
        .set('isFreemiumGroup', payload.isFreemiumGroup || false)
        .set('expiry', payload.expiry || '')
        .set('isNbSchool', payload.isNbSchool || false);
    case ACCOUNT_UPDATE_RESET:
      return state
        .set('loadingDetails', false)
        .set('errorDetails', null)
        .set('successDetails', false)
        .set('loadingAddress', false)
        .set('errorAddress', null)
        .set('successAddress', false)
        .set('loadingPassword', false)
        .set('errorPassword', null)
        .set('successPassword', false)
        .set('loadingSchoolCode', false)
        .set('errorSchoolCode', null)
        .set('successSchoolCode', false);
    case ACCOUNT_DETAILS_UPDATE_REQUEST:
      return state
        .set('loadingDetails', true)
        .set('errorDetails', null)
        .set('successDetails', false);
    case ACCOUNT_DETAILS_UPDATE_FAIL:
      return state.set('loadingDetails', false).set('errorDetails', payload);
    case ACCOUNT_DETAILS_UPDATE_SUCCESS:
      return state
        .set('loadingDetails', false)
        .set('errorDetails', null)
        .set('firstname', payload.firstname || '')
        .set('lastname', payload.lastname || '')
        .set('email', payload.email || '')
        .set('successDetails', true)
        .set('hasCompletedConsumerEndSurvey', payload.hasCompletedConsumerEndSurvey || false);
    case ACCOUNT_PASSWORD_UPDATE_REQUEST:
      return state
        .set('loadingPassword', true)
        .set('errorPassword', null)
        .set('successPassword', false);
    case ACCOUNT_PASSWORD_UPDATE_FAIL:
      return state.set('loadingPassword', false).set('errorPassword', payload);
    case ACCOUNT_PASSWORD_UPDATE_SUCCESS:
      return state
        .set('loadingPassword', false)
        .set('errorPassword', null)
        .set('successPassword', true);
    case ACCOUNT_SCHOOL_CODE_UPDATE_REQUEST:
      return state
        .set('loadingSchoolCode', true)
        .set('errorSchoolCode', null)
        .set('successSchoolCode', false);
    case ACCOUNT_SCHOOL_CODE_UPDATE_FAIL:
      return state
        .set('loadingSchoolCode', false)
        .set('errorSchoolCode', payload)
        .set('successSchoolCode', false);
    case ACCOUNT_SCHOOL_CODE_UPDATE_SUCCESS:
      return state
        .set('loadingSchoolCode', false)
        .set('errorSchoolCode', null)
        .set('successSchoolCode', true)
        .set('code', payload.code || '')
        .set('isConsumerGroup', payload.isConsumerGroup || false)
        .set('emailDomainWhitelist', payload.emailDomainWhitelist || [])
        .set('emailDomainBlacklist', payload.emailDomainBlacklist || []);
    case ACCOUNT_ADDRESS_UPDATE_REQUEST:
      return state
        .set('loadingAddress', true)
        .set('errorAddress', null)
        .set('successAddress', false);
    case ACCOUNT_ADDRESS_UPDATE_FAIL:
      return state.set('loadingAddress', false).set('errorAddress', payload);
    case ACCOUNT_ADDRESS_UPDATE_SUCCESS:
      return state
        .set('loadingAddress', false)
        .set('errorAddress', null)
        .set('successAddress', true)
        .set('address', payload.address || '')
        .set('schoolname', payload.schoolname || '')
        .set('city', payload.city || '')
        .set('state', payload.state || '')
        .set('country', payload.country || '')
        .set('postalzip', payload.postalzip || '')
        .set('phone', payload.phone || '');
    // case GET_USER_DATA_SUCCESS:
    //   for (let key in payload) {
    //     //@ts-ignore
    //     state = state.set(key, payload[key]);
    //   }
    //   return state;
    // // Requests
    // case USER_DETAILS_UPDATE_REQUEST:
    //   return INITIAL_STATE;
    // case USER_DETAILS_UPDATE_FAIL:
    //   return INITIAL_STATE;
    // case USER_DETAILS_UPDATE_SUCCESS:
    //   return INITIAL_STATE;
    // case USER_ADDRESS_UPDATE_REQUEST:
    //   return INITIAL_STATE;
    // case USER_ADDRESS_UPDATE_FAIL:
    //   return INITIAL_STATE;
    // case USER_ADDRESS_UPDATE_SUCCESS:
    //   return INITIAL_STATE;
    // case USER_PASSWORD_UPDATE_REQUEST:
    //   return INITIAL_STATE;
    // case USER_PASSWORD_UPDATE_FAIL:
    //   return INITIAL_STATE;
    // case USER_PASSWORD_UPDATE_SUCCESS:
    //   return INITIAL_STATE;
    default:
      return state;
  }
};
