import styled from 'styled-components';

const StudentTableContainer = styled.div`
  .student-account-enabled {
    display: flex;
    justify-content: center;
  }
  .student-account-email {
    text-overflow: ellipsis;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
  }
  .student-download {
    width: 175px;
  }
  .student-table-sort-button {
    white-space: normal;
  }
`;

export { StudentTableContainer };
