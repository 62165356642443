import React, { useContext, useState } from 'react';
import CardSortStyles, {
  Card,
  CardsWrapper,
  ProgressWrapper,
  UndoWrapper,
  InfoWrapper,
  InfoWrapperContainer
} from './styles';
import { AppState } from '../../redux/reducer';
import { Push } from 'connected-react-router';
import ProgressBar from '../ProgressBar';
import LikeButton from '../LikeButton';
import { Back } from '../../styles/assets';
import { CardEnum, cardTypes } from '../Card';
import { FaInfo } from 'react-icons/fa';
import LanguageContext from '../../locale/LanguageContext';
// import { CustomCardAttributes } from '../../util/card';

type LikeCallback = () => void;
export type StateItem = { name: CardEnum | string; result: null | string; cards: any };

interface IProps {}
interface StateProps {
  router: AppState['router'];
  accountReducer: AppState['accountReducer']
  onDislike: LikeCallback;
  onLike: LikeCallback;
  onSuperLike: LikeCallback;
  onUndo: LikeCallback;
  onClickCard: (type: ReturnType<typeof cardTypes>) => LikeCallback;
  cards: any[]; // StateItem | CustomCardAttributes
  index: string;
}
interface DispatchProps {
  push: Push;
}

type Props = IProps & StateProps & DispatchProps;

const CardSort: React.FC<Props> = props => {
  const { translate, language } = useContext(LanguageContext);
  const [active, setActive] = useState('');
  const { cards, onDislike, onLike, onSuperLike, onUndo, onClickCard, index } = props;
  const progress = (+props.index + 1) / (cards.length + 1);
  const isNbSchool = props.accountReducer.get('isNbSchool');
  const role = props.accountReducer.get('role');
  const cardObj = cards[+index - 1];
  const type = cardTypes(cardObj.name, isNbSchool, role, language, cardObj);

  return (
    <CardSortStyles>
      <ProgressWrapper>
        <ProgressBar
          min={1}
          max={cards.length}
          value={+props.index}
          progress={progress}
          label={translate('AriaLabel.ProgressBar')}
        />
        <UndoWrapper onClick={onUndo} hidden={+index === 1}>
          <Back />
          {translate('CardSort.Back')}
        </UndoWrapper>
      </ProgressWrapper>
      <CardsWrapper onClick={onClickCard(type)}>
        {cards && cards.length > 0 && (
          <>
            {cards.map((value, key) => (
              <Card
                visible={key === +index - 1}
                key={value?._id || `Card-${key}`}
                type={value.name}
                cardObj={value}
                logoImageUrl={value?.logoImageUrl || ''}
                watermarkImageUrl={value?.watermarkImageUrl}
              />
            ))}
          </>
        )}
        <span>{type.name}</span>
        <InfoWrapperContainer>
          <InfoWrapper aria-label={translate('CardSort.Info')}>
            <FaInfo />
          </InfoWrapper>
        </InfoWrapperContainer>
      </CardsWrapper>
      <div>
        <LikeButton
          variant="dislike"
          label={translate('CardSort.NotInterested')}
          onClick={onDislike}
          hidden={active !== 'dislike'}
          onMouseEnter={() => setActive('dislike')}
        />
        <LikeButton
          variant="like"
          label={translate('CardSort.Interested')}
          onClick={onLike}
          hidden={active !== 'like'}
          onMouseEnter={() => setActive('like')}
        />
        <LikeButton
          variant="superlike"
          label={translate('CardSort.SuperInterested')}
          onClick={onSuperLike}
          hidden={active !== 'superlike'}
          onMouseEnter={() => setActive('superlike')}
        />
      </div>
    </CardSortStyles>
  );
};

export default CardSort;
